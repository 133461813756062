import React from "react";

import Container from "components/Container";

import rectangleBackground from "assets/rectangle.svg";

const Footer = () => {
  return (
    <Container
      className="py-12 bg-no-repeat bg-left bg-16 md:bg-32 lg:bg-contain"
      style={{ backgroundImage: `url(${rectangleBackground})` }}
    >
      <div className="flex-1 my-8 lg:mt-4">
        <div className="text-left">
          <h2 className="font-header tracking-tight leading-tight font-extrabold text-purple-600 text-3xl sm:text-4xl">
            You’re just a Tidbit
            <br />
            away from your goals.
          </h2>
          <p className="mt-4 text-base leading-tight text-purple-400 font-semibold sm:mt-5 sm:text-lg md:mt-8 md:text-2xl">
            We’re just a Tidbit away.
            <br className="block" />
            Contact us at{" "}
            <a
              className="no-underline hover:underline"
              href="mailto:help@tidbit.site"
            >
              help@tidbit.site
            </a>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
