import React from "react";
import classNames from "classnames";
import horizontalPadding from "constants/style";

/**
 * @param {{children: JSX.Element, className?: string, style?: string}} Props
 */
const Container = ({ children, className, style }) => {
  return (
    <div
      className={classNames(horizontalPadding, `mt-4 md:mt-12`, className)}
      style={style}
    >
      {children}
    </div>
  );
};

export default Container;
